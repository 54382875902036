<script>
    import { mapGetters, mapActions, mapState } from 'vuex';
    import Faq from './faq';
    import Feedback from './feedback';
    import ReviewAppeal from './review-appeal';
    import Status from './status';
    import authFetch from 'auth-fetch.js';

export default {
    
    components: {
        'faq': Faq,
        'feedback': Feedback,
        'review-appeals': ReviewAppeal,
        'status': Status,
    },
    methods: {
        ...mapActions([
            'setPage',
            'setUserId',
            'removePage'
        ]),

    },
    data: function () {
        return {
            user_exists: false
        }
    },
    computed: {
        
        ...mapGetters([
            'getPages',
            'getCurrentPage',
        ]),
        
        ...mapState({
            state: state => {
                try{
                    return state;
                }catch{
                    return undefined;
                }
            }
        }),
        
        pages(){
            return this.getPages();
        },
        
        currentPage(){
            return this.getCurrentPage();
        },

    },

}
</script>


<template lang="pug">
    
    .feedback
        
        .feedback__wrapper
           
            transition(name="fade" mode="out-in") 
                h1.feedback__title(:key="currentPage") {{currentPage}} 

            transition-group(name="list" class="feedback__tabs feedback-tabs") 
                .feedback-tabs__tab(
                    v-for='page in pages'
                    :key="page"
                    @click='setPage(page)') {{page}}

            transition(name="component")
                feedback(v-show="currentPage === 'Обращения граждан'")

            transition(name="component")
                review-appeals(v-show="currentPage === 'Обзор обращений граждан'")

            transition(name="component")
                faq(v-show="currentPage=== 'Часто задаваемые вопросы'")

            transition(name="component")
                status(v-show="currentPage=== 'Проверить статус обращений'")

</template>

<style lang="scss">
@import "./src/scss/main.scss";
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "appeal" }, [
    _c(
      "div",
      { staticClass: "feedback__rules feedback-rules" },
      [
        _c("h2", { staticClass: "feedback__subtitle" }, [
          _vm._v(
            " Правила рассмотрения обращения граждан\nв форме электронного документа"
          )
        ]),
        _c("p", { staticClass: "feedback__text" }, [
          _vm._v(
            "Перед направлением обращения \nв форме электронного документа, пожалуйста, ознакомьтесь с\nизложенными ниже правилами работы данного интерактивного сервиса.\nПравила рассмотрения обращений граждан в форме \nэлектронного документа. "
          )
        ]),
        !_vm.showmore
          ? _c(
              "div",
              {
                staticClass: "feedback__show-more",
                on: {
                  click: function($event) {
                    _vm.showmore = true
                  }
                }
              },
              [_vm._v("развернуть")]
            )
          : _vm._e(),
        _vm.showmore
          ? _c(
              "div",
              {
                staticClass: "feedback__show-more",
                on: {
                  click: function($event) {
                    _vm.showmore = false
                  }
                }
              },
              [_vm._v("свернуть")]
            )
          : _vm._e(),
        _c(
          "transition",
          { attrs: { name: "fade" } },
          [
            _c("showmore", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showmore,
                  expression: "showmore"
                }
              ]
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "feedback__appeal feedback-appeal" },
      [
        _c("h2", { staticClass: "feedback__subtitle" }, [_vm._v("Обращение")]),
        _c("div", { staticClass: "feedback__row" }, [
          _c("label", { staticClass: "feedback-appeal__label" }, [
            _vm._v("Вы:")
          ]),
          _c("div", { staticClass: "feedback-appeal__radio-button" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.message.entity,
                  expression: "message.entity"
                }
              ],
              staticClass:
                "feedback-appeal__radio feedback-appeal__radio_individual",
              attrs: {
                type: "radio",
                name: "person",
                checked: "",
                value: "individual",
                id: "1"
              },
              domProps: { checked: _vm._q(_vm.message.entity, "individual") },
              on: {
                change: function($event) {
                  return _vm.$set(_vm.message, "entity", "individual")
                }
              }
            }),
            _c(
              "label",
              { staticClass: "feedback-appeal__label", attrs: { for: "1" } },
              [_vm._v("Физическое лицо")]
            )
          ]),
          _c("div", { staticClass: "feedback-appeal__radio-button" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.message.entity,
                  expression: "message.entity"
                }
              ],
              staticClass:
                "feedback-appeal__radio feedback-appeal__radio_entity",
              attrs: {
                type: "radio",
                name: "person",
                value: "entity",
                id: "2"
              },
              domProps: { checked: _vm._q(_vm.message.entity, "entity") },
              on: {
                change: function($event) {
                  return _vm.$set(_vm.message, "entity", "entity")
                }
              }
            }),
            _c(
              "label",
              { staticClass: "feedback-appeal__label", attrs: { for: "2" } },
              [_vm._v("Юридическое лицо")]
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "feedback__row feedback__row_space-between" },
          [
            _c("div", { staticClass: "feedback__item" }, [
              _c("label", { staticClass: "feedback-appeal__field-name" }, [
                _vm._v("Фамилия:")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.message.surname,
                    expression: "message.surname"
                  }
                ],
                staticClass: "feedback-appeal__input",
                attrs: { type: "text" },
                domProps: { value: _vm.message.surname },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.message, "surname", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "feedback__item" }, [
              _c("label", { staticClass: "feedback-appeal__field-name" }, [
                _vm._v("Имя:")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.message.name,
                    expression: "message.name"
                  }
                ],
                staticClass: "feedback-appeal__input",
                attrs: { type: "text" },
                domProps: { value: _vm.message.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.message, "name", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "feedback__item" }, [
              _c("label", { staticClass: "feedback-appeal__field-name" }, [
                _vm._v("Отчество:")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.message.patronymic,
                    expression: "message.patronymic"
                  }
                ],
                staticClass: "feedback-appeal__input",
                attrs: { type: "text" },
                domProps: { value: _vm.message.patronymic },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.message, "patronymic", $event.target.value)
                  }
                }
              })
            ])
          ]
        ),
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.message.entity === "entity"
            ? _c(
                "div",
                {
                  staticClass:
                    "feedback__row feedback__row_space-between feedback__row_entity"
                },
                [
                  _c("div", { staticClass: "feedback__item" }, [
                    _c(
                      "label",
                      { staticClass: "feedback-appeal__field-name" },
                      [_vm._v("Наименование:")]
                    ),
                    _c("input", {
                      staticClass: "feedback-appeal__input",
                      attrs: { type: "text" }
                    })
                  ]),
                  _c("div", { staticClass: "feedback__item" }, [
                    _c(
                      "label",
                      { staticClass: "feedback-appeal__field-name" },
                      [_vm._v("Форма собственности:")]
                    ),
                    _c("input", {
                      staticClass: "feedback-appeal__input",
                      attrs: { type: "text" }
                    })
                  ]),
                  _c("div", { staticClass: "feedback__item" }, [
                    _c(
                      "label",
                      { staticClass: "feedback-appeal__field-name" },
                      [_vm._v("Должность:")]
                    ),
                    _c("input", {
                      staticClass: "feedback-appeal__input",
                      attrs: { type: "text" }
                    })
                  ])
                ]
              )
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "feedback__row feedback__row_space-between" },
          [
            _c(
              "div",
              { staticClass: "feedback__item feedback__item_mailing" },
              [
                _c("label", { staticClass: "feedback-appeal__field-name" }, [
                  _vm._v("Номер телефона:")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.message.phone,
                      expression: "message.phone"
                    }
                  ],
                  staticClass: "feedback-appeal__input",
                  attrs: { type: "text" },
                  domProps: { value: _vm.message.phone },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.message, "phone", $event.target.value)
                    }
                  }
                })
              ]
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "feedback__row feedback__row_space-between" },
          [
            _c(
              "div",
              { staticClass: "feedback__item feedback__item_mailing" },
              [
                _c("label", { staticClass: "feedback-appeal__field-name" }, [
                  _vm._v("Адрес электронной почты:")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.message.email,
                      expression: "message.email"
                    }
                  ],
                  staticClass: "feedback-appeal__input",
                  attrs: { type: "text" },
                  domProps: { value: _vm.message.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.message, "email", $event.target.value)
                    }
                  }
                })
              ]
            ),
            _c("div", { staticClass: "feedback__item" }, [
              _c(
                "div",
                {
                  staticClass:
                    "feedback__row feedback__row_space-between feedback__row_mailing"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.message.getByMail,
                        expression: "message.getByMail"
                      }
                    ],
                    staticClass: "feedback-appeal__checkbox",
                    attrs: { type: "checkbox", id: "3" },
                    domProps: {
                      checked: Array.isArray(_vm.message.getByMail)
                        ? _vm._i(_vm.message.getByMail, null) > -1
                        : _vm.message.getByMail
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.message.getByMail,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.message,
                                "getByMail",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.message,
                                "getByMail",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.message, "getByMail", $$c)
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass:
                        "feedback-appeal__field-name feedback-appeal__field-name_m0",
                      attrs: { for: "3" }
                    },
                    [_vm._v("Получить ответ почтовым отправлением")]
                  )
                ]
              ),
              _c("input", {
                staticClass:
                  "feedback-appeal__input feedback-appeal__input_mailing",
                attrs: { type: "text", disabled: !_vm.message.getByMail }
              })
            ])
          ]
        ),
        _c("div", { staticClass: "feedback__row" }, [
          _c(
            "div",
            { staticClass: "feedback__item feedback__item_attach-document" },
            [
              _c("label", { staticClass: "feedback-appeal__field-name" }, [
                _vm._v("Текст обращения")
              ]),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.message.message,
                    expression: "message.message"
                  }
                ],
                staticClass: "feedback-appeal__textarea",
                domProps: { value: _vm.message.message },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.message, "message", $event.target.value)
                  }
                }
              }),
              _vm._m(0)
            ]
          )
        ]),
        _vm._m(1),
        _vm.siteKey !== undefined
          ? _c("vue-recaptcha", {
              staticClass: "feedback__captcha",
              attrs: { sitekey: _vm.siteKey }
            })
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "feedback__button",
            on: {
              click: function($event) {
                return _vm.send(_vm.message)
              }
            }
          },
          [_vm._v("Отправить")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "feedback-appeal__attach-document", attrs: { href: "#" } },
      [
        _vm._v("прикрепить документ "),
        _c(
          "span",
          {
            staticClass:
              "feedback-appeal__attach-document feedback-appeal__attach-document_gray"
          },
          [_vm._v("(объем файла не более 2мб)")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "feedback-agree" }, [
      _c("div", { staticClass: "feedback__row" }, [
        _c("input", {
          staticClass: "feedback-appeal__checkbox",
          attrs: { type: "checkbox", id: "4" }
        }),
        _c(
          "label",
          {
            staticClass:
              "feedback-appeal__field-name feedback-appeal__field-name_m0",
            attrs: { for: "4" }
          },
          [_vm._v("Согласен на обработку персональных данных")]
        )
      ]),
      _c("div", { staticClass: "feedback__row" }, [
        _c("input", {
          staticClass: "feedback-appeal__checkbox",
          attrs: { type: "checkbox", id: "5" }
        }),
        _c(
          "label",
          {
            staticClass:
              "feedback-appeal__field-name feedback-appeal__field-name_m0",
            attrs: { for: "5" }
          },
          [_vm._v("Согласен на публикацию обращения")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
"use strict";

export default {
    pages: [
        'Обзор обращений граждан', 
        'Часто задаваемые вопросы', 
        'Проверить статус обращений'
    ],
    currentPage: 'Обращения граждан',
    params: undefined,
    data: undefined,
    questions: undefined,
    answers: undefined,
    user: undefined,
    siteKey: undefined,
};

<script>
    import { mapGetters } from 'vuex';
    
    export default {
    
        data: _ => {
            return { 
                questionsPull: [],
            }
        },
        components: {
            
        },
        computed: {
            ...mapGetters([
                'getUser',
                'getQuestions',
            ]),
            questions() {
                return this.getQuestions();
            },
        }
    
    }
</script>
<template lang="pug">

.status
    h2.feedback__subtitle Статус обращений граждан

    table.feedback-status

        tr(v-for="question in questions")
            th.feedback-status__cell.feedback-status__cell_head {{ question.id }}
            th.feedback-status__cell.feedback-status__cell_head {{ question.creationDate }}
            th.feedback-status__cell.feedback-status__cell_head {{ `${question.info.name.value} ${question.info.patronymic.value}` }}

</template>


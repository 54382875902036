"use strict";

export default async function(url, params = {}, key = 'jwt') {

    let tokens = localStorage.getItem(key);

    if (tokens !== null)
        tokens = JSON.parse(tokens);
    else throw { message: 'authorization required' };

    try {

        url = new URL(url);
    } catch(e) {

        url = new URL(url, location.origin);
    } finally {

        url.searchParams.set('jwt', tokens['accessToken']);

        let response = await fetch(url, params);

        if (response.status == 409) {

            let link = tokens._links['refresher'].href + '?token=' + tokens['refreshToken'];
            let newResponse = await fetch(link, { method: 'POST'});

            if (newResponse.status == 409) throw { message: 'authorization required' };
            else {

                let newTokens = await newResponse.json();
                newTokens._links = tokens._links;
                localStorage.setItem(key, JSON.stringify(newTokens));
                url.searchParams.set('jwt', newTokens['accessToken']);
                response = await fetch(url, params);
            }
        }

        return response;
    }
};

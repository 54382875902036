var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "review-appeal" }, [
    _c(
      "div",
      { staticClass: "feedback__review feedback-review" },
      _vm._l(_vm.questions, function(question) {
        return _c(
          "div",
          { staticClass: "feedback-review__item" },
          [
            _c("div", { staticClass: "feedback-review__question" }, [
              _c("div", { staticClass: "feedback__row" }, [
                _c("div", { staticClass: "feedback-review__date" }, [
                  _vm._v(" " + _vm._s(question.creationDate))
                ]),
                _c("div", { staticClass: "feedback-review__name" }, [
                  _vm._v(_vm._s(question.info.name.value))
                ])
              ]),
              _c("div", { staticClass: "feedback-review__block" }, [
                _vm._v(_vm._s(question.message))
              ])
            ]),
            _c("answers", { attrs: { question: question } })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "feedback" }, [
    _c(
      "div",
      { staticClass: "feedback__wrapper" },
      [
        _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
          _c("h1", { key: _vm.currentPage, staticClass: "feedback__title" }, [
            _vm._v(_vm._s(_vm.currentPage) + " ")
          ])
        ]),
        _c(
          "transition-group",
          {
            staticClass: "feedback__tabs feedback-tabs",
            attrs: { name: "list" }
          },
          _vm._l(_vm.pages, function(page) {
            return _c(
              "div",
              {
                key: page,
                staticClass: "feedback-tabs__tab",
                on: {
                  click: function($event) {
                    return _vm.setPage(page)
                  }
                }
              },
              [_vm._v(_vm._s(page))]
            )
          }),
          0
        ),
        _c(
          "transition",
          { attrs: { name: "component" } },
          [
            _c("feedback", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentPage === "Обращения граждан",
                  expression: "currentPage === 'Обращения граждан'"
                }
              ]
            })
          ],
          1
        ),
        _c(
          "transition",
          { attrs: { name: "component" } },
          [
            _c("review-appeals", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentPage === "Обзор обращений граждан",
                  expression: "currentPage === 'Обзор обращений граждан'"
                }
              ]
            })
          ],
          1
        ),
        _c(
          "transition",
          { attrs: { name: "component" } },
          [
            _c("faq", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentPage === "Часто задаваемые вопросы",
                  expression: "currentPage=== 'Часто задаваемые вопросы'"
                }
              ]
            })
          ],
          1
        ),
        _c(
          "transition",
          { attrs: { name: "component" } },
          [
            _c("status", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentPage === "Проверить статус обращений",
                  expression: "currentPage=== 'Проверить статус обращений'"
                }
              ]
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<script>
import { mapGetters, mapActions } from 'vuex';
import ShowMore from './show-more';
import {createMask} from 'imask';    
import authFetch from 'auth-fetch.js';
import VueRecaptcha from 'vue-recaptcha';

export default {
    components: {
        'showmore': ShowMore,
        'vue-recaptcha': VueRecaptcha,
    },
    computed: {
        ...mapGetters([
            'getSiteKey'
        ]),
        siteKey() {
            return this.getSiteKey();
        }
    },
    data: _ => {
        return{ 
            showmore: false,
            message: {
                surname: '',
                name: '',
                patronymic: '',
                message: '',
                phone: '',
                email: '',
                i_agree:  true,
                user_id: '',
                phoneMask: createMask({mask: "+{7}(000)000-00-00"}),
            },
        }
    },
    async created() {
        let js = document.createElement('script');
        js.setAttribute('src', "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit");
        js.setAttribute('async', true);
        js.setAttribute('defer', true);
        document.head.appendChild(js);
    },
    async mounted() {

        let link = this.$root.params.userLink
        try {
            let response = await authFetch(link, {}, 'personal-jwt');
            let result = await response.json();

            let props = result._embedded.props;

            this.message.surname = props.surname;
            this.message.name = props.name;
            this.message.patronymic = props.patronymic;

            this.message.user_id = result.id;
            this.message.email = result.email;
            this.message.phone = this.phoneMask.resolve(result.phone);
        } catch(e) {
            return;
        }
    },
    methods: {
        ...mapActions([
            'send'
        ])
    },
}
</script>
<template lang="pug">
.appeal 
    .feedback__rules.feedback-rules

        h2.feedback__subtitle 

            |Правила рассмотрения обращения граждан
            |в форме электронного документа

        p.feedback__text

            |Перед направлением обращения 
            |в форме электронного документа, пожалуйста, ознакомьтесь с
            |изложенными ниже правилами работы данного интерактивного сервиса.
            |Правила рассмотрения обращений граждан в форме 
            |электронного документа. 

        .feedback__show-more(v-if="!showmore" @click="showmore = true") развернуть
        .feedback__show-more(v-if="showmore" @click="showmore = false") свернуть
        
        transition(name="fade")
            showmore(v-show="showmore")

    .feedback__appeal.feedback-appeal

        h2.feedback__subtitle Обращение

        .feedback__row

            label.feedback-appeal__label Вы:

            .feedback-appeal__radio-button

                input(type="radio" name="person" checked value="individual" v-model="message.entity" id="1").feedback-appeal__radio.feedback-appeal__radio_individual

                label.feedback-appeal__label(for="1") Физическое лицо
            
            .feedback-appeal__radio-button

                input(type="radio" name="person"  value="entity" v-model="message.entity" id="2").feedback-appeal__radio.feedback-appeal__radio_entity

                label.feedback-appeal__label(for="2") Юридическое лицо

        .feedback__row.feedback__row_space-between

            .feedback__item

                label.feedback-appeal__field-name Фамилия:

                input.feedback-appeal__input(type="text" v-model="message.surname")

            .feedback__item

                label.feedback-appeal__field-name Имя:

                input.feedback-appeal__input(type="text" v-model="message.name")

            .feedback__item

                label.feedback-appeal__field-name Отчество:

                input.feedback-appeal__input(type="text" v-model="message.patronymic")

        transition(name="fade")
            .feedback__row.feedback__row_space-between.feedback__row_entity(v-if="message.entity === 'entity'")

                .feedback__item

                    label.feedback-appeal__field-name Наименование:

                    input.feedback-appeal__input(type="text")

                .feedback__item

                    label.feedback-appeal__field-name Форма собственности:

                    input.feedback-appeal__input(type="text")

                .feedback__item

                    label.feedback-appeal__field-name Должность:

                    input.feedback-appeal__input(type="text")
        

        .feedback__row.feedback__row_space-between

            .feedback__item.feedback__item_mailing

                label.feedback-appeal__field-name Номер телефона:

                input.feedback-appeal__input(type="text" v-model="message.phone") 

        .feedback__row.feedback__row_space-between

            .feedback__item.feedback__item_mailing

                label.feedback-appeal__field-name Адрес электронной почты:

                input.feedback-appeal__input(type="text" v-model="message.email") 


            .feedback__item

                .feedback__row.feedback__row_space-between.feedback__row_mailing

                    input.feedback-appeal__checkbox(type="checkbox"  v-model='message.getByMail' id="3")

                    label.feedback-appeal__field-name.feedback-appeal__field-name_m0(for="3") Получить ответ почтовым отправлением

                input.feedback-appeal__input.feedback-appeal__input_mailing(type="text" :disabled="!message.getByMail")

        .feedback__row

            .feedback__item.feedback__item_attach-document

                label.feedback-appeal__field-name Текст обращения

                textarea(v-model="message.message").feedback-appeal__textarea

                a(href="#").feedback-appeal__attach-document прикрепить документ 
                    
                    span.feedback-appeal__attach-document.feedback-appeal__attach-document_gray (объем файла не более 2мб)

        .feedback-agree

            .feedback__row

                input.feedback-appeal__checkbox(type="checkbox" id="4")

                label.feedback-appeal__field-name.feedback-appeal__field-name_m0(for="4") Согласен на обработку персональных данных

            .feedback__row

                input.feedback-appeal__checkbox(type="checkbox" id="5")

                label.feedback-appeal__field-name.feedback-appeal__field-name_m0(for="5") Согласен на публикацию обращения

        vue-recaptcha.feedback__captcha(:sitekey='siteKey' v-if='siteKey !== undefined')

        button.feedback__button(@click="send(message)") Отправить

</template>


<script>
import { mapGetters, mapActions } from 'vuex';

export default {

    props: [
        "question",
    ],
    data: function() {
        return {
            answers: null,
            renderAnswer: false,
        }

    },
    computed: {
        ...mapGetters([
            'getQuestion'
        ]),
    },
    
    async created() {
        let link = this.question._links.answers.href;
        let res = await fetch(link);
        this.answers = await res.json();
    }
}

</script>

<template lang="pug">

.feedback-review__answer(v-if="answers")
    span.feedback__show-more.feedback__show-more_mb10(
        v-if="answers._embedded.items[0] && !renderAnswer" 
        @click="renderAnswer = !renderAnswer") Показать ответ 
    
    span.feedback__show-more.feedback__show-more_mb10(
        v-if="answers._embedded.items[0] && renderAnswer" 
        @click="renderAnswer = !renderAnswer") Скрыть ответ 

    transition-group(class ="feedback-review__answers-wrapper" tag="div" v-if="renderAnswer" name="list")

        .feedback__row(v-for="answer in answers._embedded.items" :key="answer.id")

            .feedback-review__date {{ answer.creationDate }} 

                .feedback-review__name {{ answer.info.user.value }} 

            .feedback-review__block {{ answer.message }}


            <!-- transition-group(name="list" class="feedback__tabs feedback-tabs") --> 
            <!--     .feedback-tabs__tab( -->
            <!--         v-for='page in pages' -->
            <!--         :key="page" -->
            <!--         @click='setPage(page)') {{page}} -->

</template>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
<style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.answers
    ? _c(
        "div",
        { staticClass: "feedback-review__answer" },
        [
          _vm.answers._embedded.items[0] && !_vm.renderAnswer
            ? _c(
                "span",
                {
                  staticClass: "feedback__show-more feedback__show-more_mb10",
                  on: {
                    click: function($event) {
                      _vm.renderAnswer = !_vm.renderAnswer
                    }
                  }
                },
                [_vm._v("Показать ответ ")]
              )
            : _vm._e(),
          _vm.answers._embedded.items[0] && _vm.renderAnswer
            ? _c(
                "span",
                {
                  staticClass: "feedback__show-more feedback__show-more_mb10",
                  on: {
                    click: function($event) {
                      _vm.renderAnswer = !_vm.renderAnswer
                    }
                  }
                },
                [_vm._v("Скрыть ответ ")]
              )
            : _vm._e(),
          _vm.renderAnswer
            ? _c(
                "transition-group",
                {
                  staticClass: "feedback-review__answers-wrapper",
                  attrs: { tag: "div", name: "list" }
                },
                _vm._l(_vm.answers._embedded.items, function(answer) {
                  return _c(
                    "div",
                    { key: answer.id, staticClass: "feedback__row" },
                    [
                      _c("div", { staticClass: "feedback-review__date" }, [
                        _vm._v(_vm._s(answer.creationDate) + " "),
                        _c("div", { staticClass: "feedback-review__name" }, [
                          _vm._v(_vm._s(answer.info.user.value) + " ")
                        ])
                      ]),
                      _c("div", { staticClass: "feedback-review__block" }, [
                        _vm._v(_vm._s(answer.message))
                      ])
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<script>
    import { mapGetters, mapActions } from 'vuex';
    import answers from './answer';
    
    export default {
        
        components: {
            'answers': answers,
        }, 
        data: _ => {
            return {
                counter: 0,
            };
        },
        computed: {
            ...mapGetters([
                'getQuestions',
                'getAnswers'
            ]),
            
            questions() {
                return this.getQuestions();
            },
            answers() {
                return this.getAnswers();
            }
        },
        methods: {
            ...mapActions(['setAnswers']),
        }
        
    }
</script>

<template lang="pug">
.review-appeal

    .feedback__review.feedback-review

        .feedback-review__item(v-for="question in questions")

            .feedback-review__question

                .feedback__row

                    .feedback-review__date  {{ question.creationDate }}

                    .feedback-review__name {{ question.info.name.value }}

                .feedback-review__block {{ question.message}}

            answers(:question="question")

</template>

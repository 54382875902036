'use strict';

import authFetch from 'auth-fetch.js';
import VueRecaptcha from 'vue-recaptcha';
import Noty from 'noty';

const options = {
    credentials: 'include',
};


export default {

    async init(context, params) {
        try {

            let res = await fetch(params.apiLink);

            if (!res.ok)
                throw new Error('Ошибка');

            let data = await res.json();

            let questions = await fetch(data._links.questions.href);
            questions = await questions.json();

            let siteKey = data.siteKey;
            let answers = []; 
            answers = context.getters.getAnswers();

            context.commit('init', { params, data, questions, answers, siteKey });

        } catch(e) {
            console.log(e);
        }
    },

    async send(context, message) {

        let token = window.grecaptcha.getResponse();
        window.grecaptcha.reset();

        if (token) {

            let link = context.state.data._links.questions.href;

            let body = {
                grecaptchaToken: token,
                name: message.name,
                surname: message.surname,
                patronymic: message.patronymic,
                phones: [message.phone],
                emails: [message.email],
                message: message.message,
                i_agree: message.i_agree,
                user_id: message.user_id,
            }
            let response = await fetch(link, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify( body ),
            });
            if(response.ok) {
                new Noty({
                    theme: 'sunset',
                    type: 'success',
                    text: 'Ваше сообщение успешно отправлено',
                }).show();
            } else {
                new Noty({
                    theme: 'mint',
                    type: 'error',
                    text: 'Произошла непредвиденная ошибка, повторите попытку позже',
                }).show();
            }
        }

    },

    setPage(context, page){
        context.commit('setCurrentPage', page);
    },

    async setAnswers(context, questionId) {

        let link = context.getters.getQuestion(questionId)._links.answers.href;

        try {

            let res = await fetch(link, { ...options });

            if (!res.ok)
                throw new Error('Ошибка');

            let answers = await res.json();

            context.commit('setAnswers',  answers );

        } catch (e) {
            console.log(e);
        }

    },
}

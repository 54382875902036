var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "feedback__faq feedback-faq" }, [
      _vm._v(
        "1. Обращения, направленные в форме электронного документа через официальный сайт, поступают на рассмотрение  и рассматриваются работниками уполномоченными на то лицами."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "2. Обращение, направленное через официальный сайт образовательной организации, подлежит обязательной регистрации и рассмотрению в соответствии с требованиями Федерального закона от 02.05.2006 г. N 59-ФЗ «О порядке рассмотрения обращений граждан Российской Федерации»."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "3. Обращение регистрируется в течение трёх рабочих дней и направляется на исполнение должностным лицам в соответствии с их компетенцией."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "4. Обращение рассматривается в течение 30 дней со дня регистрации."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "5. На обращение, содержащее вопросы, решение которых не входит в компетенцию образовательной организации, ответ по существу обращения не даётся, о чём гражданин уведомляется в ответе."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "6. Ответ заявителю обращения направляется по электронному адресу, указанному при заполнении формы обращения."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "7. При рассмотрении обращения не допускается разглашение сведений, содержащихся в обращении, а также сведений, касающихся частной жизни гражданина, без его согласия. Информация о персональных данных заявителей хранится и обрабатывается с соблюдением требований российского законодательства о персональных данных."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "8. Обращения, поступившие через официальный сайт, анализируются и обобщаются руководством образовательной организации. На наиболее часто задаваемые вопросы периодически публикуются ответы в рубрике «Часто задаваемые вопросы»."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "9. Гражданин может отслеживать статус зарегистрированного обращения, используя сервис «Проверить статус обращения»."
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
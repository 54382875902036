'use strict';

export default {
    getQuestions(state, getters) {
        return _ => {
            try {
                return state.questions._embedded.items;
            } catch(e) {
                return [];
            }
        }
    },
    getQuestion(state, getters) {
        return id => {
            try {
                return getters.getQuestions().find(question => question.id === id);
            } catch(e) {
                return undefined;
            }
        }
    },
    getAnswers(state, getters) {
        return _ => {
            try {
                return state.answers;
            } catch(e) {
                return [];
            }
        }
    },
    getSiteKey(state, getters) {
         return _ => {
            try {
                return state.siteKey;
            } catch(e) {
                return [];
            }
        }       
    },

    getPages(state, getters) {
        return _ => {
            try {
                return state.pages
            } catch(e) {
                return [];
            }
        }
    },
    getCurrentPage(state, getters) {
        return _ => {
            try {
                return state.currentPage;
            } catch(e) {
                return [];
            }
        }
    },
}

'use strict';

import Vue from 'vue';

export default {

    init(state, { params, data, questions, answers, siteKey }) {
        state.params = params;
        state.data = data;
        state.questions = questions;
        state.answers = answers;
        state.siteKey = siteKey;
    },
    setMessage(state,message) {
        state.message = message;
    },
    setAnswers(state,answers) {
        state.answers = answers
    },
    setCurrentPage(state, page){
        
        let prevPage = state.currentPage;
        
        state.currentPage = page;
        let index = state.pages.indexOf(page);
        state.pages.splice(index, 1);
        state.pages.push(prevPage);
    },
    removePage(state, page) {
        let index = state.pages.indexOf(page);
        state.pages.splice(index,1);
    } 
}

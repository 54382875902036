var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "status" }, [
    _c("h2", { staticClass: "feedback__subtitle" }, [
      _vm._v("Статус обращений граждан")
    ]),
    _c(
      "table",
      { staticClass: "feedback-status" },
      _vm._l(_vm.questions, function(question) {
        return _c("tr", [
          _c(
            "th",
            { staticClass: "feedback-status__cell feedback-status__cell_head" },
            [_vm._v(_vm._s(question.id))]
          ),
          _c(
            "th",
            { staticClass: "feedback-status__cell feedback-status__cell_head" },
            [_vm._v(_vm._s(question.creationDate))]
          ),
          _c(
            "th",
            { staticClass: "feedback-status__cell feedback-status__cell_head" },
            [
              _vm._v(
                _vm._s(
                  question.info.name.value +
                    " " +
                    question.info.patronymic.value
                )
              )
            ]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }